














































import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { format, intlFormatDistance, minutesToMilliseconds } from "date-fns";
import ErrorAlert from "@/components/ErrorAlert.vue";
import { reserveerApi } from "@/lib/backend";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import Card from "@/lib/pfg/vue2/card/Card.vue";
import CardContent from "@/lib/pfg/vue2/card/CardContent.vue";
import CardHeader from "@/lib/pfg/vue2/card/CardHeader.vue";
import CardTitle from "@/lib/pfg/vue2/card/CardTitle.vue";
import Skeleton from "@/lib/pfg/vue2/skeleton/Skeleton.vue";

export default defineComponent({
	components: { ErrorAlert, Card, CardTitle, CardHeader, CardContent, Skeleton },
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const { status, data, error } = useQuery({
			queryKey: ["member", props.memberId, "appointments", format(new Date(), "dd-MM-yy")] as const,
			queryFn: async (context) =>
				await reserveerApi.fitnessafspraken
					.getAfsprakenVoorLid(
						context.queryKey[1],
						{
							limit: 1,
							orderby_datum: "desc",
							datum_lte: new Date().toISOString(),
							status: "afgelopen",
						},
						{ signal: context.signal },
					)
					.then((response) => response.data),
			refetchInterval: minutesToMilliseconds(30),
		});

		const lastAppointment = computed(() => data.value?.[0]);
		const lastAppointmentActions = computed(() =>
			lastAppointment.value?.aanwezigheid
				? Object.entries(lastAppointment.value?.aanwezigheid)
						.filter(([key]) => Object.keys(APPOINTMENT_MAP).includes(key))
						.filter(([, value]) => value === true)
						.map<(typeof APPOINTMENT_MAP)[number] & { key: string }>(([key]) => ({
							key,
							...APPOINTMENT_MAP[key],
						}))
				: [],
		);

		return {
			status,
			data,
			error,
			lastAppointment,
			lastAppointmentActions,
			intlFormatDistance,
		};
	},
});
